/* .header {
    border: 1px solid black;
    margin: 1rem;
    padding: 2rem 2rem;
    text-align: center;
  } */
/* .sync-monitor-parent{
  width: max-content;
} */

  .tenant-text-field {
    display: inline-block;
    padding: 1.5rem 0rem;
    vertical-align: top;
    margin-left: 50%;
    min-height: 0rem;
  }

  .header {
    display: inline-block;
    padding: 0rem 0rem;
    vertical-align: top;
    margin-left: 0%;
    font: Nunito Sans;
    font-size: 18px;
    color: #595959; 
    text-align: center;   
  }
  .date-range-picker{
    display: inline-block;
    padding: 1.5rem 0rem;
    vertical-align: top;
    float: right;
    margin-right: 1rem;
  }

  .bottom-pannel-sync-monitor{
    display: inline-block;
  }

  .left-pannel-sync-monitor{
    display: inline-block;
    width: 30%;
    height: 50px;
    float: left;
  }

  .right-pannel-sync-monitor{
    display: inline-block;
    width: 70%;
    float: right;
  }