body {
  margin: 0;
  font-family:'Nunito Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
#contentframe{
  width: 100%;
  overflow-y: hidden;
  height: 100vh;
}
